import config from '@/config/config';
import Axios from 'axios';

export default new class NewsService {
    private config = config.getConfig();
    getToken() {
        return localStorage.getItem('__token');
    }

    async saveNew(data: any) {
        // You should have a server side REST API 
        try {
            return (await Axios.post(`${this.config.apiUrl}/news`,
                data, {
                headers: {
                    'Authorization': `Bearer ${this.getToken()}`
                }
            })).data;
        } catch (e) {
            return e.response.data;
        }
    }
    async updateNew(id: string, data: any) {
        // You should have a server side REST API 
        try {
            return (await Axios.put(`${this.config.apiUrl}/news/${id}`,
                data, {
                headers: {
                    'Authorization': `Bearer ${this.getToken()}`
                }
            })).data;
        } catch (e) {
            return e.response.data;
        }
    }
    async deleteNew(id: string) {
        // You should have a server side REST API 
        try {
            return (await Axios.delete(`${this.config.apiUrl}/news/${id}`, {
                headers: {
                    'Authorization': `Bearer ${this.getToken()}`
                }
            })).data;
        } catch (e) {
            return e.response.data;
        }
    }
    async getAllNews() {
        // You should have a server side REST API 
        try {
            return (await Axios.get(`${this.config.apiUrl}/news`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.getToken()}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }
    async getNew(id: string) {
        // You should have a server side REST API 
        try {
            return (await Axios.get(`${this.config.apiUrl}/news/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.getToken()}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }
};

