
import { defineComponent } from "vue"

export default defineComponent({
    emits: ["change"],

    data() {
      return {
        files: [],
      }
    },

    methods: {
      openFileExplorer() {
            const input = document.createElement("input")

            input.type = "file"
            input.multiple = true
            input.click()

            const newFiles = [];
            input.onchange = () => {
                for (const file of input.files) {
                    newFiles.push({ label: file.name, blob: file })
                }
                this.files = [...this.files, ...newFiles]
                this.$emit("change", this.files)
            }
        },

        removeFile: function(index: number) {
            this.files = this.files.filter((v, i) => i !== index);
            this.$emit("change", this.files);
        }
      },
})
