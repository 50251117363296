import config from "@/config/config"
import axios from "axios"

export function fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.onload = () => resolve(reader.result.toString())
        reader.onerror = (err) => reject(err)
    })
}

export async function uploadFile(file: File): Promise<string> {
    const { apiUrl } = config.getConfig()

    const data = new FormData()
    data.append("file", file)

    const headers = {
        "authorization": `Bearer ${localStorage.getItem("__token")}`,
        "content-type": "multipart/form-data"
    }

    const response = await axios.post(`${apiUrl}/file`, data, { headers: headers })
    return response.data
}